import React, { useState, useEffect } from 'react';

const ChatBar = ({ socket }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    socket.on('newUserResponse', (data) => {
      setUsers(data.user);
      console.log(data);
    });

    const fetchToken = () => {
      const token = localStorage.getItem('authToken');
      if (token) {
        socket.emit('UserResponse', token);
      }
    };

    fetchToken();

    return () => {
      socket.off('newUserResponse');
    };
  }, [socket]);

  return (
    <div className="chat-sidebar">
      <h2>Open Chat</h2>
      <div className="chat-active-users">
        <h4 className="chat-header">Active Users</h4>
        <div className="chat-users">
            <div key={users?.socketID} className="user-card">
              <div className="user-avatar">
                <img
                  src={`https://robohash.org/${users?.userId}.png?size=50x50`} // Unique avatar
                  alt={users?.userId}
                />
              </div>
              <p className="user-name">{users?.userId}</p>
              <span className="user-role">{users?.role}</span>
            </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBar;
