import React, { useState, useEffect } from 'react';

const ChatFooterAdmin = ({ socket, selectedUser,handleSendMessages }) => {
  const [message, setMessage] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(null);

  // Emit typing status
  const handleTyping = () => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    // Set a new timeout to avoid sending too many typing events
    const timeout = setTimeout(() => {
      socket.emit('typing', `${localStorage.getItem('userId')} is typing...`);
    }, 500); // Delay typing event to avoid excessive triggers
    setTypingTimeout(timeout);
  };

  // Handle sending message
  const handleSendMessage = (e) => {
    e.preventDefault();
    handleSendMessages(message)
      setMessage(''); // Clear the message input after sending
    
  };

  useEffect(() => {
    // Clear typing timeout when the component unmounts
    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [typingTimeout]);

  return (
    <div className="chat__footer">
      <form className="form" onSubmit={handleSendMessage}>
        <input
          type="text"
          placeholder="Write message"
          className="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleTyping} // Track key press for typing status
        />
        <button className="sendBtn">SEND</button>
      </form>
    </div>
  );
};

export default ChatFooterAdmin;
