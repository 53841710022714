  import React, { useEffect, useState } from 'react';

  const ChatFooter = ({ socket,handleSendMessages }) => {
    const [message, setMessage] = useState('');


    const handleTyping = () =>
      socket.emit('typing', `${localStorage.getItem('userId')} is typing`);

    const handleSendMessage = (e) => {
      e.preventDefault();
      handleSendMessages(message)
      setMessage('');
    };
    return (
      <div className="chat__footer">
  <form className="form" onSubmit={handleSendMessage}>
          <input
            type="text"
            placeholder="Write message"
            className="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleTyping}
          /> 
          <button className="sendBtn">SEND</button>
        </form>
  
      </div>
    );
  };

  export default ChatFooter;