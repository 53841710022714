import React, { useEffect, useState, useRef } from 'react';
import ChatBarAdmin from './ChatBarAdmin';
import ChatBodyAdmin from './ChatBodyAdmin';
import ChatFooterAdmin from './ChatFooterAdmin';

const ChatPageAdmin = ({ socket }) => {
  const [messages, setMessages] = useState([]);
  const [typingStatus, setTypingStatus] = useState('');
  const [selectedUser, setSelectedUser] = useState(null); // Store selected user
  const lastMessageRef = useRef(null);

  
  useEffect(() => {
    const token = localStorage.getItem('authToken'); // Get token from localStorage

    if (token) {
      socket.emit('updateSocketio', token);
    }
  }, [socket])
  useEffect(() => {
    socket.on('messageResponse', (data) => setMessages([...messages, data]));
  }, [socket, messages]);
  useEffect(() => {
    socket.on('typingResponse', (data) => setTypingStatus(data));
  }, [socket]);
  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);
  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

     const handleSendMessage = (messageText) => {
    if (selectedUser) {
      const message = {
        text: messageText,  
        senderId: localStorage.getItem('userId'),
        recipientSocketID: selectedUser._id,
         // Send to selected user's socketID
      };
      socket.emit('message', message);  // Send message to the server

      // Update messages array with the new message (preserve previous messages)
      setMessages((prevMessages) => [...prevMessages,message]);
    }
  };
  useEffect(() => {
    if (selectedUser) {
      // Fetch messages between th0e logged-in user and the selected user
      fetch(`https://chat.al-bassif.com/api/messages/${localStorage.getItem('userId')}/${selectedUser.userId}`)
        .then(response => response.json())
        .then(data =>{
            const ma=[]; 
            data.map(value=>{
                ma.push({id:value._id,senderId:value.senderId,text:value.message})
            })
            setMessages(ma)
            console.log({data})
        })
        .catch(error => console.error('Error fetching messages:', error));
    }
  }, [selectedUser, localStorage.getItem('userId')]);  // Re-fetch when selectedUser or userId changes


  return (
    <div className="chat">
      <ChatBarAdmin socket={socket} setSelectedUser={setSelectedUser} />
      <div className="chat__main">
        {selectedUser ? (
          <ChatBodyAdmin
            messages={messages}
            typingStatus={typingStatus}
            lastMessageRef={lastMessageRef}
            selectedUser={selectedUser}
          />
        ) : (
          <p>Select a user to start chatting</p> // Display message when no user is selected
        )}
        <ChatFooterAdmin socket={socket} selectedUser={selectedUser} handleSendMessages={handleSendMessage}  />
      </div>
    </div>
  );
};

export default ChatPageAdmin;
