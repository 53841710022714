import React, { useState, useEffect } from 'react';

const ChatBarAdmin = ({ socket, setSelectedUser }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Listen for user updates from the server
    socket.on('newUserResponseAdmin', (data) => {
      setUsers(data.user);
      console.log(data);
    });

    const fetchToken = () => {
      const token = localStorage.getItem('authToken');
      if (token) {
        socket.emit('UserResponseadmin', token);
      }
    };

    fetchToken();

    return () => {
      socket.off('newUserResponseAdmin');
    };
  }, [socket]);

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  return (
    <div className="chat-sidebar">
      <h2>Open Chat</h2>
      <div>
        <h4 className="chat-header">Active Users</h4>
        <div className="chat-users">
          {users.length === 0 ? (
            <p className="no-users">No active users</p>
          ) : (
            users?.map((user) => (
              <div
                key={user.userId}
                className="user-card"
                onClick={() => handleUserClick(user)}
              >
                <div className="user-avatar">
                  <img
                    src={`https://robohash.org/${user.userId}.png?size=50x50`}
                    alt={user.userId}
                  />
                </div>
                <div className="user-info">
                  <p className="user-name">{user.userId}</p>
                  <p className="user-role">{user.role}</p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatBarAdmin;
