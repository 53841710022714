import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Home = ({ socket }) => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState('');
  const [phone, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const userCredentials = { userId, phone };
    localStorage.setItem('userId', userId);

    socket.emit('loginUser', { ...userCredentials, socketID: socket.id });

    socket.on('loginSuccess', (data) => {
      localStorage.setItem('authToken', data.token);
      if (data.role === 'user') {
        navigate('/chat');
      } else if (data.role === 'admin') {
        navigate('/ChatPageAdmin');
      } else {
        navigate('/chat');
      }
    });

    socket.on('loginError', (data) => {
      console.error('Login error:', data);
    });
  };

  return (
    <div className="home__wrapper">
      <form className="home__container" onSubmit={handleSubmit}>
        <h2 className="home__header">Welcome to Open Chat</h2>
        <label htmlFor="userId">User ID</label>
        <input
          type="text"
          name="userId"
          id="userId"
          className="userId__input"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          placeholder="Enter your User ID"
          required
        />
        <label htmlFor="password">Password</label>
        <input
          type="password"
          name="password"
          id="password"
          className="password__input"
          value={phone}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter your Password"
          required
        />
        <button type="submit" className="home__cta">
          SIGN IN
        </button>
      </form>
    </div>
  );
};

export default Home;
